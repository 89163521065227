<template>
  <div class="vg_wrapper">
    <el-card v-loading="loadFlag">
      <el-button class="vg_mb_8" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()"> 刷新</el-button>
      <el-button class="vg_mb_8" size="small" type="primary" @click="generatePurchaseInvoice()"> 生成采购发票</el-button>
      <DynamicUTable
        ref="multiTable"
        :columns="tableProperties"
        :need-fixed-height="true"
        :need-search="true"
        :needPageSizes="true"
        :pageSize="50"
        :table-data="tableData"
        :totalPage="totalPage"
        @getTableData="getTableData"
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { requisitionAPI } from '@api/modules/requisition';
import { invoiceDetailsForAuxiliaryMaterialsTableProperties } from '@/views/FinanceManagement/RequisitionManage/requisition';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { getCptt, getDataCenterByPermId } from '@api/public';
import { arrayDeRepeat, getArrayIds, objectArrayDeRepeat } from '@assets/js/arrayUtils';
import { pinvAPI } from '@api/modules/pinv';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';

export default {
  name: 'RequisitionList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      tableProperties: invoiceDetailsForAuxiliaryMaterialsTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      mulitSelection: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      let mtrb_pctype_list = objectArrayDeRepeat(await getDataCenterByPermId({ id: 10024, type: 'select' }));
      let cptt_list = await getCptt();
      this.tableProperties.forEach(item => {
        if (item.label === '收票状态') item.subItem.change = (val, row) => this.saveRow(row);
        if (item.label === '包材品类') item.options = mtrb_pctype_list;
        if (item.label === '公司抬头') item.options = cptt_list;
      });
      await this.getTableData();
    },
    async getTableData() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.mtrb_pctype_list = searchForm.mtrb_pctype_list?.toString();
      await requisitionAPI.getRequisitionMtrb(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    saveRow(row) {
      requisitionAPI.editRequisitionMtrb(row);
    },
    handleSelect(selection, row) {
      if (row.requisition_part_type > 0) {
        this.$refs.multiTable.$refs.multiTable.toggleRowSelection([{ row: row, selected: false }]);
        this.$message.error('所选内容有已生成采购发票，不可再次生成');
      }
      let { cptt_name, supp_abbr } = selection[0];
      if (row.cptt_name + row.supp_abbr !== cptt_name + supp_abbr) {
        this.$refs.multiTable.$refs.multiTable.toggleRowSelection([{ row: row, selected: false }]);
        this.$message.error('供应商或公司抬头不一致!');
      }
      this.mulitSelection = selection.filter(x => x.cptt_name + x.supp_abbr === cptt_name + supp_abbr);
    },
    handleSelectAll(selection) {
      if (selection.find(x => x.requisition_part_type > 0)) {
        this.$message.error('所选内容有已生成采购发票，不可再次生成!');
        this.$refs.multiTable.clearSelection();
        return;
      }
      if (arrayDeRepeat(selection.map(x => x.cptt_name + x.supp_abbr)).length > 1) {
        this.$refs.multiTable.clearSelection();
        this.$message.error('供应商或公司抬头不一致!');
        return;
      }
      this.mulitSelection = selection;
    },
    generatePurchaseInvoice() {
      pinvAPI.addRequisitionMtrb(getArrayIds(this.mulitSelection, 'requisition_part_id')).then(({ data }) => {
        this.initData();
        this.jump('/pinv_edit', {
          key: UrlEncode.encode(JSON.stringify({ perm_id: 132, form_id: data.form_id }))
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
