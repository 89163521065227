import topic from '../topic';
import { postNoCatch } from '@api/request';

export const pinvAPI = {
  getAllPinvs: topic.pinvTopic + '/get_pinvs',
  getAllPinvChanges: topic.pinvchangeTopic + '/get_pinvchange',
  addPinv: topic.pinvTopic + '/add_pinv', // 采购发票添加
  addPinvChange: topic.pinvchangeTopic + '/add_pinvchange', // 采购发票添加
  editPinv: topic.pinvTopic + '/edit_pinv', // 采购发票修改
  editPinvChange: topic.pinvchangeTopic + '/edit_pinvchange', // 采购发票修改
  getAllTranPart: topic.pinvTopic + '/get_all_tran_part', // 获取出运信息里的报关明细
  deletePinvByIds: topic.pinvTopic + '/delete_pinv_by_ids',
  getPinvById: topic.pinvTopic + '/get_pinv_by_id', // 根据id查询
  getPinvByIdChange: topic.pinvchangeTopic + '/get_pinvchange_by_id', // 根据id查询
  getPinvPartByTranNo: topic.pinvTopic + '/get_pinv_part_by_tran_no',
  getPinvPartByTranNoV1: topic.pinvTopic + '/get_pinv_part_by_tran_no_v1',
  get_pinvPartByTranNo: topic.pinvTopic + '/get_pinvPartByTranNo',
  exportPinvNotice: topic.pinvTopic + '/pdf/export_pinv_notice',
  exportPinvNoticeKG: topic.pinvTopic + '/pdf/export_pinv_notice_kg',
  getPinvListByRichErp: topic.pinvTopic + '/getPinvListByRichErp',
  getPinvByAutoInc: topic.pinvTopic + '/getPinvByAutoInc',
  addPinvListByRichMax: topic.pinvTopic + '/addPinvListByRichMax',
  addOinvByRequisition: topic.oinvTopic + '/addOinvByRequisition',
  addRequisitionMtrb: params => postNoCatch(`${topic.pinvTopic}/addRequisitionMtrb`, params)
};
